<template>
  <div>
    <div class="lateral-padding md-layout md-alignment-center">
      <h1 class="md-layout-item">{{ title }}</h1>
      <search-bar class="md-layout-item" @input.native="filter = $event.target.value"/>
    </div>

    <md-list-item class="toolbar md-layout md-subheading">
      <div class="md-layout md-alignment-center md-xsmall-size-85">
        <div class="md-layout-item md-xsmall-size-100"><span>Nom</span></div>
        <div class="md-layout-item md-xsmall-size-100"><span>Prix</span></div>
        <div class="md-layout-item md-layout-item md-size-10 md-small-20 md-xsmall-size-15"></div>
      </div>
    </md-list-item>
    <md-list>
      <membership-item v-for="membership of filteredMemberships" :key="membership.id"
                       :object="membership" @deleted="removeObject"/>
    </md-list>

    <membership-form @submit="saveObject"/>
  </div>
</template>

<script>

import MembershipItem from '@/components/membership/MembershipItem.vue';
import MembershipForm from '@/components/membership/MembershipForm.vue';
import MembershipService from '@/components/membership/MembershipService';
import SearchBar from '@/components/shared/search-bar/SearchBar.vue';
import pageMixin from '@/mixins/page-mixin';
import notificationMixin from '@/mixins/notification-mixin';

export default {
  name: 'MembershipsPage',
  data: () => ({
    title: 'Abonnements',
    filter: '',
  }),
  components: {
    SearchBar,
    MembershipItem,
    MembershipForm,
  },
  mixins: [pageMixin, notificationMixin],
  computed: {
    filteredMemberships() {
      if (this.filter) {
        const exp = new RegExp(this.filter.trim(), 'i');
        return this.objects.filter((membership) => exp.test(membership.name));
      }
      return this.objects;
    },
  },
  async mounted() {
    try {
      this.objects = await MembershipService.index();
    } catch (err) {
      this.handleError(err);
    }
  },
};
</script>

<style scoped>

</style>
