<template>
  <div>
    <md-list-item class="md-layout">
      <div class="md-layout md-alignment-center md-xsmall-size-85">
        <div class="md-layout-item md-xsmall-size-100 md-list-item-text">
          <span>{{ object.name }}</span>
        </div>
        <div class="md-layout-item md-xsmall-size-100">
          <span>CHF {{ object.price }}</span>
        </div>
      </div>

      <div class="md-layout md-layout-item md-size-10 md-small-20 md-xsmall-size-15">
        <div class="btn-edit">
          <md-button @click="onEdit" class="md-icon-button md-dense md-raised md-accent btn-green">
            <md-icon>mode</md-icon>
          </md-button>
        </div>
        <md-button @click="active = true" class="md-icon-button md-dense md-raised md-accent">
          <md-icon>delete</md-icon>
        </md-button>
      </div>

    </md-list-item>

    <md-dialog-confirm
      :md-active.sync="active"
      md-title="Supprimer abonnement"
      md-content="Vous voulez vraiment supprimer cet abonnement?"
      md-confirm-text="Confirmer"
      md-cancel-text="Annuler"
      @md-confirm="onConfirm"/>

    <md-divider class="md-full"/>
  </div>
</template>

<script>
import MembershipService from '@/components/membership/MembershipService';
import itemMixin from '@/mixins/item-mixin';

export default {
  name: 'MembershipItem',
  data: () => ({
    service: MembershipService,
  }),
  mixins: [itemMixin],
};
</script>
